import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contents/:content_id',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/Page/ContentsView.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/categorys/:catid',
    name: 'Categorys',
    component: () => import(/* webpackChunkName: "categorys" */ '../views/Page/CategorysView.vue'),
    props: true,
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/Page/SearchsView.vue'),
    props: true,
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "signin" */ '../views/User/LoginView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/User/ProfileView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/subscribes',
    name: 'Subscribes',
    component: () => import(/* webpackChunkName: "subscribes" */ '../views/User/SubscribesView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Page/ContactsView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/User/UnsubscribesView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFounds',
    component: () => import(/* webpackChunkName: "notFounds" */ '../views/Page/NotFoundsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})


router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      // next();
      next('/Subscribes');
    }
   } else {
     next();
   }
})


export default router
